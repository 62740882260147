// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');


// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Datepicker Bootstrap
@import "~bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css";


// Slick
@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";

/* Fonts */
@font-face {
    font-family: 'Palanquin-Regular';
    src: url('/../font/Palanquin-Regular.eot');
    src: url('/../font/Palanquin-Regular.eot?#iefix') format('embedded-opentype'),
         url('/../font/Palanquin-Regular.woff2') format('woff2'),
         url('/../font/Palanquin-Regular.woff') format('woff'),
         url('/../font/Palanquin-Regular.ttf') format('truetype'),
         url('/../font/Palanquin-Regular.svg#Palanquin-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Prometo_BlkIt';
    src: url('/../font/Prometo_BlkItr.eot');
    src: url('/../font/Prometo_BlkIt.eot?#iefix') format('embedded-opentype'),
         url('/../font/Prometo_BlkIt.woff2') format('woff2'),
         url('/../font/Prometo_BlkIt.woff') format('woff'),
         url('/../font/Prometo_BlkIt.ttf') format('truetype'),
         url('/../font/Prometo_BlkIt.svg#Prometo_BlkIt') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Prometo_MdIt';
    src: url('/../font/Prometo_MdIt.eot');
    src: url('/../font/Prometo_MdIt.eot?#iefix') format('embedded-opentype'),
         url('/../font/Prometo_MdIt.woff2') format('woff2'),
         url('/../font/Prometo_MdIt.woff') format('woff'),
         url('/../font/Prometo_MdIt.ttf') format('truetype'),
         url('/../font/Prometo_MdIt.svg#Prometo_MdIt') format('svg');
    font-weight: normal;
    font-style: normal;
}
body {
    background: #1b1b1b;
}
.container {
    max-width: 1200px!important;
}
h2, h3 {
    margin: 0;
    padding: 0;
}
h2 {
    font-family: 'Prometo_BlkIt', sans-serif; 
    font-size: 2.5vw;
    line-height: 2.8vw;
    @media (max-width: 992px) {
        font-size: 4.5vw;
        line-height: 4.8vw;
    }
}
h3 {
    font-family: 'Prometo_MdIt', sans-serif; 
    font-size: 2vw;
    line-height: 2vw;
    @media (max-width: 992px) {
        font-size: 4vw;
        line-height: 4vw;
    }
}
p {
    font-family: 'Palanquin-Regular';
    font-size: 1.2vw;
    line-height: 2.2vw;
    @media (max-width: 992px) {
        font-size: 2.2vw;
        line-height: 3.2vw;
    }
}
p.p-campanii {
    font-family: 'Palanquin-Regular';
    font-size: 0.9vw;
    line-height: 1.5vw;
    @media (max-width: 992px) {
        font-size: 2.2vw;
        line-height: 3.2vw;
    }
    a, a:hover, a:visited, a:focus, a:active {
        color: #fff;
        text-decoration: none;
    }
    a:hover {
        text-decoration: underline;
    }
}
.bg-dark {
    background: #000!important;
}
.nav-link {
    font-family: 'Prometo_BlkIt', sans-serif;
    font-size: 15.9px;
    @media (min-width: 1000px) and (max-width: 1279px){
        font-size: 11.5px;
    }
    @media (min-width: 990px) and (max-width: 999px){
        font-size: 10px;
    }
    @media (max-width: 989px){
        font-size: 18px;
    }
    font-style: normal;
    color: #fff!important;
    text-transform: uppercase;
}
.nav-link.active {
    color: #fa6008!important;
}
@media (max-width: 992px) {
    #navbarSupportedContent.navbar-collapse {
        min-height: 100vh;
        padding-top: 80px!important;
        text-align: center;
    }
}
.navbar-toggler {
  color: #fff;
  background-color: transparent;
  border: transparent;
}
.navbar-toggler:focus {
    box-shadow: none;
}
.navbar-toggler-icon {
  width: 2em;
  height: 2em;
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.content-section {
    width:100%;
    max-width: 1180px;
    @media (max-width: 1279px) {
        max-width: 90%;
        padding-top: 10%;
    }
    height: auto;
    margin: 0 auto;
}
#acasa, #produse, #campanii, #contact {
    padding-top: 60px;
    width: 100%;
    height: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
#acasa, #castigatori {
    margin-top: 0;
    padding-top: 60px;
    background: #000;
}
#acasa {
    padding-top: 0px;
}
#castigatori {
    h2 {
        padding-top: 8%!important;
        padding-bottom: 1%!important;
    }
    .content-section {
        padding-bottom: 5%;
    }
    a, a:hover, a:visited, a:focus, a:active, p {
        display: block;
        background: #fa6008;
        border: none;
        font-size: 20px;
        @media (max-width: 1279px) {
            font-size: 14px;
        }
    }
    p {
        color: #fff;
    }
}

#produse {
    padding-top: 0;
    min-height: 960px;
    @media (max-width: 400px) {
        min-height: 640px;
    }
    @media (min-width: 401px) and (max-width: 540px) {
        min-height: 810px;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
        min-height: 1080px;
    }
    background-image: url('/images/desk-02.png');
}

@media (min-width: 1280px) {
    #campanii {
        min-height: 614px;
        background-image: url('/images/campanii-desk.png');
    }
    .bg-campanii-1 {
        display: none;
    }
}
@media (max-width: 1279px) {
    .bg-campanii-1 {
        display: block;
    }
    .bg-campanii-2 {
        width: 100%;
        height: auto;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url('/images/bkg-campanii-2-mob.png');
        padding: 0 10% 10% 10%;
    }
}

#contact {
    min-height: 834px;
    background-image: url('/images/contact-desk.png');
    
}
.section-produse {
    position: relative;
    width: 100%;
    height: auto;
    margin: 100px auto 0 auto;
    z-index: 1;
    .shape-absolute {
        width: 50%;
        position: absolute;
        top: 15%;
        left: 0;
    }
    .slider.slider-packs {
        td.align-middle {
            width: 50%;
            color: #fff;
        }
        .product-image {
            width: 80%; height: auto; margin: 0 auto;
        }
        .product-info {
            padding: 0 1rem;
        }
    }
}
.line-shape {
    margin: 1vw auto;
}
.mt-campanii {
    margin-top: 10%;
}
.slick-dotted.slick-slider {
    margin-bottom: 0;
}
.video-desk, .video-mob {
    display: none;
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.desk, .mob {
    display: none;
}

@media (min-width: 993px) {
    .video-desk {
        display: block;
    }
    .desk {
        display: block;
    }
}
@media (max-width: 992px) {
    .video-mob {
        display: block;
    }
    .mob {
        display: block;
    }
}
.slider-banner {
    div {
        width: 100vw;
        height: 90vh;
    }
    div.banner {
        width: 100%;
        height: 100%;
        img {
            width: 100%;
            height: auto;
        }
        object-fit: contain;
        background-image: url('/images/bg-video.png');
    }
}


.slider-banner .slick-dots {
    position: absolute;
    bottom: 5%;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    background: transparent;
  }
.slider-banner .slick-dots li {
    width: 32px!important;
    height: 32px!important;
}
.slider-banner ul.slick-dots {
    list-style-type: none!important;
}
.slider-banner ul.slick-dots li button::before {
    content: none!important;
}
.slider-banner .slick-dots li.slick-active button, .slider-packs .slick-dots li.slick-active button::before {
    width: 32px!important;
    height: 32px!important; 
}
.slider-banner .slick-dots li button, .slider-packs .slick-dots li button::before {
    width: 32px!important;
    height: 32px!important;
}
.slider-banner .slick-dots li button {
    background: transparent!important;
    background-image: url('/images/dot.png')!important;
}
.slider-banner .slick-dots li.slick-active button {
    background: transparent!important;
    background-image: url('/images/dot-active.png')!important;
}

@media (min-width: 541px) {
    /* Slider Packs */
    .slider-packs .slick-dots li {
        width: 108px!important;
        height: 146px!important; 
        margin: 0 2.2%;
    }
    .slider-packs ul.slick-dots {
        list-style-type: none!important;
        bottom: -25%;
    }
    .slider-packs ul.slick-dots li button::before {
        content: none!important;
    }
    .slider-packs .slick-dots li.slick-active button, .slider-packs .slick-dots li.slick-active button::before {
        width: 108px!important;
        height: 146px!important; 
    }
    .slider-packs .slick-dots li button, .slider-packs .slick-dots li button::before {
        width: 108px!important;
        height: 146px!important; 
    }
    .slider-packs .slick-dots li button#slick-slide-control10 {
        background: transparent!important;
        background-image: url('/images/packs/small/salt.png')!important;
    }
    .slider-packs .slick-dots li button#slick-slide-control11 {
        background: transparent!important;
        background-image: url('/images/packs/small/hot-corn.png')!important;
    }
    .slider-packs .slick-dots li button#slick-slide-control12 {
        background: transparent!important;
        background-image: url('/images/packs/small/nacho.png')!important;
    }
    .slider-packs .slick-dots li.slick-active button#slick-slide-control10 {
        background: transparent!important;
        background-image: url('/images/packs/active/salt.png')!important;
    }
    .slider-packs .slick-dots li.slick-active button#slick-slide-control11 {
        background: transparent!important;
        background-image: url('/images/packs/active/hot-corn.png')!important;
    }
    .slider-packs .slick-dots li.slick-active button#slick-slide-control12 {
        background: transparent!important;
        background-image: url('/images/packs/active/nacho.png')!important;
    }

    .slider-packs .slick-dots li button#slick-slide-control13 {
        background: transparent!important;
        background-image: url('/images/packs/small/barbecue.png')!important;
    }
    .slider-packs .slick-dots li button#slick-slide-control14 {
        background: transparent!important;
        background-image: url('/images/packs/small/chilli.png')!important;
    }
    .slider-packs .slick-dots li.slick-active button#slick-slide-control13 {
        background: transparent!important;
        background-image: url('/images/packs/active/barbecue.png')!important;
    }
    .slider-packs .slick-dots li.slick-active button#slick-slide-control14 {
        background: transparent!important;
        background-image: url('/images/packs/active/chilli.png')!important;
    }

    .slider-packs .slick-dots li button#slick-slide-control15 {
        background: transparent!important;
        background-image: url('/images/packs/small/hot-big.png')!important;
    }
    .slider-packs .slick-dots li button#slick-slide-control16 {
        background: transparent!important;
        background-image: url('/images/packs/small/nacho-big.png')!important;
    }
    .slider-packs .slick-dots li.slick-active button#slick-slide-control15 {
        background: transparent!important;
        background-image: url('/images/packs/active/hot-big.png')!important;
    }
    .slider-packs .slick-dots li.slick-active button#slick-slide-control16 {
        background: transparent!important;
        background-image: url('/images/packs/active/nacho-big.png')!important;
    }
    /* Arrows */
    .left {
        position: absolute; bottom: -15%; left: 0; width: 38px; cursor: pointer;
    }
    .right {
        position: absolute; bottom: -15%;  right: 0; width: 38px; cursor: pointer;
    }
}
@media (max-width: 540px) {
    /* Slider Packs */
    .slider-packs .slick-dots li {
        width: 54px!important;
        height: 73px!important; 
        margin: 0 2.2%;
    }
    .slider-packs ul.slick-dots {
        list-style-type: none!important;
        bottom: -22%;
    }
    .slider-packs ul.slick-dots li button::before {
        content: none!important;
    }
    .slider-packs .slick-dots li.slick-active button, .slider-packs .slick-dots li.slick-active button::before {
        width: 54px!important;
        height: 73px!important; 
    }
    .slider-packs .slick-dots li button, .slider-packs .slick-dots li button::before {
        width: 54px!important;
        height: 73px!important; 
    }

    .slider-packs .slick-dots li button#slick-slide-control10 {
        background: transparent!important;
        background-image: url('/images/packs/mob/small/salt.png')!important;
    }
    .slider-packs .slick-dots li button#slick-slide-control11 {
        background: transparent!important;
        background-image: url('/images/packs/mob/small/hot-corn.png')!important;
    }
    .slider-packs .slick-dots li button#slick-slide-control12 {
        background: transparent!important;
        background-image: url('/images/packs/mob/small/nacho.png')!important;
    }
    .slider-packs .slick-dots li.slick-active button#slick-slide-control10 {
        background: transparent!important;
        background-image: url('/images/packs/mob/active/salt.png')!important;
    }
    .slider-packs .slick-dots li.slick-active button#slick-slide-control11 {
        background: transparent!important;
        background-image: url('/images/packs/mob/active/hot-corn.png')!important;
    }
    .slider-packs .slick-dots li.slick-active button#slick-slide-control12 {
        background: transparent!important;
        background-image: url('/images/packs/mob/active/nacho.png')!important;
    }

    .slider-packs .slick-dots li button#slick-slide-control13 {
        background: transparent!important;
        background-image: url('/images/packs/mob/small/barbecue.png')!important;
    }
    .slider-packs .slick-dots li button#slick-slide-control14 {
        background: transparent!important;
        background-image: url('/images/packs/mob/small/chilli.png')!important;
    }
    .slider-packs .slick-dots li.slick-active button#slick-slide-control13 {
        background: transparent!important;
        background-image: url('/images/packs/mob/active/barbecue.png')!important;
    }
    .slider-packs .slick-dots li.slick-active button#slick-slide-control14 {
        background: transparent!important;
        background-image: url('/images/packs/mob/active/chilli.png')!important;
    }

    .slider-packs .slick-dots li button#slick-slide-control15 {
        background: transparent!important;
        background-image: url('/images/packs/mob/small/hot-big.png')!important;
    }
    .slider-packs .slick-dots li button#slick-slide-control16 {
        background: transparent!important;
        background-image: url('/images/packs/mob/small/nacho-big.png')!important;
    }
    .slider-packs .slick-dots li.slick-active button#slick-slide-control15 {
        background: transparent!important;
        background-image: url('/images/packs/mob/active/hot-big.png')!important;
    }
    .slider-packs .slick-dots li.slick-active button#slick-slide-control16 {
        background: transparent!important;
        background-image: url('/images/packs/mob/active/nacho-big.png')!important;
    }
    /* Arrows */
    .left {
        position: absolute; bottom: -15%; left: 2%; width: 28px; cursor: pointer;
    }
    .right {
        position: absolute; bottom: -15%;  right: 2%; width: 28px; cursor: pointer;
    }
}
@media (max-width: 992px) {
    /*    limit dots slider */
    .slider-packs .slick-dots {
      text-align: center;
    }
    .slider-packs .slick-dots li {
        display: none;
    /*  margin: 0 5px;*/
    }
    .slider-packs .slick-dots li.slick-active,
    .slider-packs .slick-dots li.slick-active + li,
    .slider-packs .slick-dots li.slick-active + li + li {
        display: inline-block;
    }

    .slider-packs .slick-dots li:nth-last-child(1),
    .slider-packs .slick-dots li:nth-last-child(2),
    .slider-packs .slick-dots li:nth-last-child(3) {
        display: inline-block;
    }

    .slider-packs .slick-dots li.slick-active ~ li:nth-last-child(1),
    .slider-packs .slick-dots li.slick-active ~ li:nth-last-child(2),
    .slider-packs .slick-dots li.slick-active ~ li:nth-last-child(3) {
        display: none;
    }
    .slider-packs .slick-dots li.slick-active + li + li:nth-last-child(3),
    .slider-packs .slick-dots li.slick-active + li + li:nth-last-child(2),
    .slider-packs .slick-dots li.slick-active + li + li:nth-last-child(1),
    .slider-packs .slick-dots li.slick-active + li:nth-last-child(3),
    .slider-packs .slick-dots li.slick-active + li:nth-last-child(2),
    .slider-packs .slick-dots li.slick-active + li:nth-last-child(1){
        display: inline-block;
    }
    /* Arrows */
    .left {
        left: 10%;
    }
    .right {
        right: 10%;
    }
    .section-produse .slider.slider-packs td.align-middle {
        width: 100%;
        color: #fff;
        float: left;
    }
    .section-produse .slider.slider-packs .product-image {
        width: 50%;
    }
    .section-produse .shape-absolute {
        width: 60%;
        position: absolute;
        top: 15%;
        left: 20%;
    }
    .section-produse .slider.slider-packs .product-info {
        padding: 10% 10% 0 10%;
    }
}

#footer {
    background: #1b1b1b!important;
    font-family: 'Palanquin-Regular';
    color: #fff !important;
    font-style: normal;
    padding-top: 1%;
    padding-bottom: 1%;
    @media (min-width: 900px) and (max-width: 1279px) {
        padding-top: 10%;
        padding-top: 4%;
    }
    .logo {
        max-width: 80px;
    }
    a {
        color: #fff !important;
        text-decoration: none;
    }
    .footer-link {
        a {
            font-size: 14px;
            font-family: 'Prometo_BlkIt', sans-serif;
            text-transform: uppercase;
            margin-right: 5%;
            @media (max-width: 1279px) {
                display: block;
                margin: 2% auto;
            }
        }
    }
}

.sound-inner, .play-inner {
    font-family: 'Prometo_BlkIt';
    width: 32px!important;
    height: 32px!important;
    z-index: 10;
    cursor: pointer;
}
.sound-inner {
     position: absolute; bottom: 11%; right: 30%;
     @media (max-width: 1279px) {
         bottom: 2%; right: 20%;
     }
     .off, .on {
         position: absolute; top: 0; left: 0; padding: 0 5px;
     }
}
.play-inner {
    position: absolute; bottom: 11%; right: 27%;
    @media (max-width: 1279px) {
         bottom: 2%; right: 10%;
     }
    .play, .pause {
        position: absolute; top: 0; left: 0; padding: 0 5px;
    }
}
@media (min-width: 800px) {
    .row-campanii {
        max-width: 590px!important;
    }
}
@media (max-width: 1279px) {
    .box-campanie{
        margin-top: 60px;
    }
}

.content-form {
    font-family: "Prometo_BlkIt";
    background: #ed1e24;
    width: 100%;
    max-width: 1180px;
    @media (max-width: 1279px) {
        max-width: 90%;
    }
    height: auto;
    margin: 0 auto;
    h2 {
        color: #fff200;
        font-size: 2.3rem;
        line-height: 2.3rem;
        @media (max-width: 1279px) {
            font-size: 18px;
            line-height: 18px;
        }
    }
}
.content-form.content-form-2023 {
    width: 100%;
    max-width: 1260px;
    background: #080300;
    @media (min-width: 1279px) {
        margin-left: 20%;
    }
}
@media (max-width: 480px) {
.box-campanie {
        img.mob {
            padding-top: 6%;
        }
    }
}
.btn-campanii, .btn-campanii:hover, .btn-campanii:active, .btn-campanii:visited, .btn-campanii:focus {
    font-family: 'Prometo_BlkIt';
    color: #fff;
    background: #f36c21;
    background: #000;
    text-decoration: none;
    font-size: 2.3rem;
    line-height: 3rem;
    @media (max-width: 1279px) {
        font-size: 18px;
        line-height: 22px;
    }
    padding-bottom: 0;
    border-color: transparent;
    text-transform: uppercase;
    margin: 2%;
}

/*   Formular   */
#participa-form {
    margin: 10% auto;
}
.checkbox {
	text-align: left;
}
.control-label {
    font-size: 1.2rem;
    line-height: 1.2rem;
    @media (max-width: 1279px) {
        font-size: 14px;
        line-height: 14px;
    }
    margin-top: 1%;
    @media (max-width: 992px) {
        margin-top: 3%;
    }
    color: #fff;
}
.txt-obligatoriu {
    font-size: 0.85rem;
    @media (max-width: 1279px) {
        font-size: 12px;
    }
    color: #fff;
    text-align: left;
}
.form-control:-moz-placeholder {
	color: #fff;
	opacity: 1;
	filter: alpha(opacity=100);
}

.form-control::-webkit-input-placeholder {
	color: #fff;
	opacity: 1;
	filter: alpha(opacity=100);
}

.form-control:-ms-input-placeholder {
	color: #fff;
	opacity: 1;
	filter: alpha(opacity=100);
}
.form-control::-moz-placeholder {
    color: #1b77dc;
    opacity: 1;
}
.form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.6;
    color: #000;
    background-color: #f8fafc;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}


input:-ms-input-placeholder, input::-moz-placeholder { /* IE 10+ */
  color: #1b77dc;
  opacity: 1;
}
input[type="checkbox"]:focus {
	outline: none;
}
.checkboxTC {
	text-align: left;
	margin: 0;
}
.checkboxTC label {
	color: #fff;
	margin: 0;
	text-transform: uppercase;
}
.checkboxTC label#acord_regulament-error {
	color: red;
}
.checkboxTC label span.txtLabel {
	color: #fff;
	font-size: 100%;
	margin-top: 1rem;
	font-size: 0.9rem;
	display: inline-block;
	vertical-align: middle;
	padding-right: 0px;
	text-transform: none;
        @media (max-width: 1279px) {
            max-width: 88%;
        }
	a, a:hover, a:visited, a:active, a:focus {
                color: #fff;
		display: inline-block;
		font-size: 0.9rem;
		text-decoration: underline;
		font-weight: normal;
	}
	a:hover {
		text-decoration: none;
	}
}
    .c-input {
        color: #fff;
        cursor: pointer;
        display: inline;
        padding-left: 2.5rem;
        padding-top: 1rem;
        position: relative;
    }

.c-input > input {
    opacity: 0;
    position: absolute;
    z-index: -1;
}
.c-input > input:checked ~ .c-indicator {
    background-color: #000;
    color: #fff;
}
.c-input > input:focus ~ .c-indicator {
    box-shadow: none;
}
.c-input > input:active ~ .c-indicator {
    background-color: #fff;
    color: #fff;
}
.c-input + .c-input {
    margin-left: 1rem;
}

.c-indicator {
    -moz-user-select: none;
    background-color: #fff;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 50% 50%;
    color: #fff;
    display: block;
    font-size: 100%;
    height: 2rem;
    left: 0;
    line-height: 2rem;
    position: absolute;
    text-align: center;
    top: 12px;
    width: 2rem;
}
.c-checkbox .c-indicator {
    border-radius: 0rem;
}
.c-checkbox input:checked ~ .c-indicator {
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTYuNCwxTDUuNywxLjdMMi45LDQuNUwyLjEsMy43TDEuNCwzTDAsNC40bDAuNywwLjdsMS41LDEuNWwwLjcsMC43bDAuNy0wLjdsMy41LTMuNWwwLjctMC43TDYuNCwxTDYuNCwxeiINCgkvPg0KPC9zdmc+DQo=");
}
.c-checkbox input:indeterminate ~ .c-indicator {
    background-color: #fff;
/*    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iOHB4IiBoZWlnaHQ9IjhweCIgdmlld0JveD0iMCAwIDggOCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgOCA4IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0wLDN2Mmg4VjNIMHoiLz4NCjwvc3ZnPg0K");*/
}
.c-radio .c-indicator {
    border-radius: 50%;
}
.c-radio input:checked ~ .c-indicator {
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTQsMUMyLjMsMSwxLDIuMywxLDRzMS4zLDMsMywzczMtMS4zLDMtM1M1LjcsMSw0LDF6Ii8+DQo8L3N2Zz4NCg==");
}

/* Modal */


.modal-dialog {
    max-width: 800px;
}
.modal-content {
    background-color: #ed1e24!important;
    color: #fff;
    font-family: 'Prometo_BlkIt';
    font-size: 1.4rem;
    @media (max-width: 1279px) {
        font-size: 16px;
    }
    a, a:hover, a:visited, a:focus, a:active {
        color: #fff;
        font-family: 'Prometo_BlkIt';
        font-size: 1.4rem;
        @media (max-width: 1279px) {
            font-size: 16px;
        }
    }
    -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
}
.modal-header {
    border-color: #ed1e24!important;
}
.modal-backdrop {
    opacity: 0.8;
}
.btn-close, .btn-close:focus, btn-close:active  {
    opacity: 1;
    border: 0!important;
    outline: none!important;
    box-shadow: none;
}
.img-crl-all {
    width: 20%;
    float: left;
    margin-right: 5px;
    margin-bottom: 2%;
    @media (max-width: 640px) {
        width: 30%;
        margin-right: 10px;
    }
}
.img-mega-all {
    width: 7.2%;
    float: left;
    margin-right: 10px;
    margin-bottom: 2%;
    @media (max-width: 640px) {
        width: 15%;
        margin-right: 10px;
    }
}
#dezabonare {
    font-family: "Prometo_BlkIt", sans-serif;
    background: #000;
    h1, h5 {
        color: #fff;
    }
    .btn-campanii, .btn-campanii:hover, .btn-campanii:active, .btn-campanii:visited, .btn-campanii:focus {
        background: #fa6008;
    }
    .help-block {
        color: red;
        strong {
            font-weight: normal;
        }
    }
}


// spinner loading
// spinner loading
.overlay {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background: #222;
    z-index: 10000;
    opacity: 0.6;
}

.overlay__inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.overlay__content {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.spinner {
    width: 75px;
    height: 75px;
    display: inline-block;
    border-width: 2px;
    border-color: rgba(255, 255, 255, 0.05);
    border-top-color: #fff;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
    width: 75px;
    height: 75px;
    display: inline-block;
    border-width: 2px;
    border-color: rgba(255, 255, 255, 0.05);
    border-top-color: #fff;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
}
.content-form-2023 {
    .c-input > input:checked ~ .c-indicator {
        background-color: #e41b21;
    }
}

.land, .port {
    display: none;
}
@media screen and (min-width: 993px) { 
    .land {
        display: block;
    }
}

@media screen and (max-width: 992px) and (orientation: portrait) {
    .port {
        display: block;
    }
}
@media screen and (max-width: 992px) and (orientation: landscape) {
    .land {
        display: block;
    }
}

#doritosBoldSquad {
    padding-top: 60px; position: relative;
    .btn-descopera-aici {
        @media screen and (min-width: 993px) { 
            position: absolute; top: 83%; left: 0%; width: 100%;
            a {
                width: 18%;
                margin: 0 auto;
            }
        }
        @media screen and (max-width: 992px) and (orientation: landscape) {
            position: absolute; top: 83%; left: 0%; width: 100%;
            a {
                width: 18%;
                margin: 0 auto;
            }
        }
        @media screen and (max-width: 992px) and (orientation: portrait) {
            position: absolute; top: 84%; left: 0%; width: 100%;
            a {
                width: 60%;
                margin: 0 auto;
            }
        }
        img {
            width: 100%;
        }
        
    }
}